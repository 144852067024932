import {
  BrowserModule,
  BrowserTransferStateModule,
  HammerModule,
  makeStateKey,
  TransferState,
} from '@angular/platform-browser';
import {
  Inject,
  Injectable,
  LOCALE_ID,
  NgModule,
  NgZone,
  Optional,
  PLATFORM_ID,
} from '@angular/core';

import { AppComponent } from './app.component';
import { Router, RouterModule, Scroll } from '@angular/router';
import { AppConfigService } from '@tremaze/shared/util-app-config';
import { environment } from '../environments/environment';
import { JsonSerializer } from '@tremaze/shared/util-json-serializer';
import { AppStateService } from '@tremaze/shared/util-app-state';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {
  DateAdapter,
  MAT_DATE_LOCALE,
  MatNativeDateModule,
} from '@angular/material/core';
import { TremazeDateAdapter } from '@tremaze/shared/util-date/angular/adapter';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  isPlatformBrowser,
  isPlatformServer,
  registerLocaleData,
  ViewportScroller,
} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { CookieModule } from 'ngx-cookie';
import { SharedCoreAuthV2Module } from '@tremaze/shared/core/auth-v2';
import { RemoteClientAuthV2DataSource } from '@tremaze/shared/core/auth-v2/data-access';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import {
  ActionReducer,
  MetaReducer,
  Store,
  StoreModule,
  UPDATE,
} from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { TremazeDate } from '@tremaze/shared/util-date';
import { SharedRouterModule } from '@tremaze/shared/router';
import { filter } from 'rxjs/operators';
import { BaseInterceptor } from '@tremaze/shared/core/base-http-interceptor';
import { default as packageInfo } from '../../../../package.json';
import { SharedCoreLocalDatabaseModule } from '@tremaze/shared/core/local-database';
import { ProfileDataSource } from '@tremaze/shared/feature/profile/data-access';
import { ClientWebsiteProfileDataSource } from '@tremaze/tremaze-client-website/feature/profile/data-access';
import { MatDialogModule } from '@angular/material/dialog';
import 'zone.js/plugins/task-tracking';

const ENABLE_STATE_LOGGING = true;

export function stateSetter(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state: any, action: any) {
    const { type } = action;
    if (type?.startsWith('[')) {
      const i = type.indexOf(']');
      const api = type.slice(0, i + 1);
      const color = type.includes('Failure')
        ? 'red'
        : api.includes('API]')
        ? 'magenta'
        : 'green';
      const act = type.slice(i + 1);
      if (i >= 0 && ENABLE_STATE_LOGGING) {
        console.log(`%c${api}%c${act}`, `color: ${color}`, '');
      }
    }
    if (action.type === 'SET_ROOT_STATE') {
      return action.payload;
    }

    if (action.type === UPDATE && state && action.features) {
      const features: string[] = (action as any).features;
      const newState = { ...state };
      for (const feature of features) {
        newState[feature] = newState[feature] || state[feature];
      }
      return reducer(newState, action);
    }

    return reducer(state, action);
  };
}

const _metaReducers: MetaReducer<any>[] = [stateSetter];

export const metaReducers = _metaReducers;

export const NGRX_STATE = makeStateKey('NGRX_STATE');

declare const process;

const TREMAZE_WEBSITE_CONFIG_SERVICE_KEY = makeStateKey(
  'TREMAZE_WEBSITE_CONFIG_SERVICE_KEY'
);

@Injectable({ providedIn: 'root' })
export class TremazeWebsiteConfigService implements AppConfigService {
  public static isTenant: boolean;

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    @Optional() private transferState: TransferState
  ) {
    const isBrowser = this.transferState.hasKey<any>(
      TREMAZE_WEBSITE_CONFIG_SERVICE_KEY
    );
    if (isBrowser) {
      this.onBrowser();
    } else {
      this.onServer();
    }
    TremazeWebsiteConfigService.isTenant = !this.instId;
  }

  get isProd(): boolean {
    return this.state === 'PROD';
  }

  private _instId: string;

  get instId() {
    if (isPlatformServer(this.platformId) && process?.env?.instId) {
      return process?.env?.instId;
    }
    return this._instId ?? environment.institutionId;
  }

  private _basePath: string;

  get basePath() {
    if (isPlatformServer(this.platformId) && process?.env?.basePath) {
      return process?.env?.basePath;
    }
    return this._basePath ?? environment.basePath;
  }

  private _clientSecret: string;

  get clientSecret(): string {
    if (isPlatformServer(this.platformId) && process?.env?.clientSecret) {
      return process?.env?.clientSecret;
    }
    return this._clientSecret ?? environment.clientSecret;
  }

  get version(): string {
    return packageInfo.version;
  }

  get jsonIgnoreFields(): string[] {
    return ['subPrivileges'];
  }

  get state(): 'PROD' | 'DEV' | 'QS' {
    return environment.production ? 'PROD' : 'DEV';
  }

  private _clientId: string;

  get clientId(): string {
    if (isPlatformServer(this.platformId) && process?.env?.clientId) {
      return process?.env?.clientId;
    }
    return this._clientId ?? 'tremaze-web';
  }

  get tokenPath(): string {
    return '/oauth/token';
  }

  get disableCustomerSelection() {
    return true;
  }

  get logoPaths(): { light: string; dark: string } {
    return {
      light: '/assets/images/tremaze-bw.png',
      dark: '/assets/images/tremaze.png',
    };
  }

  private onServer() {
    this.transferState.onSerialize(TREMAZE_WEBSITE_CONFIG_SERVICE_KEY, () => {
      return {
        instId: this.instId,
        basePath: this.basePath,
        clientSecret: this.clientSecret,
        clientId: this.clientId,
      };
    });
  }

  private onBrowser() {
    const state = this.transferState.get<any>(
      TREMAZE_WEBSITE_CONFIG_SERVICE_KEY,
      null
    );
    this.transferState.remove(TREMAZE_WEBSITE_CONFIG_SERVICE_KEY);
    this._instId = state.instId;
    this._basePath = state.basePath;
    this._clientSecret = state.clientSecret;
    this._clientId = state.clientId;
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    HammerModule,
    SharedCoreLocalDatabaseModule,
    MatNativeDateModule,
    StoreRouterConnectingModule.forRoot(),
    MatDialogModule,
    SharedRouterModule.forRoot(),
    StoreModule.forRoot({ router: routerReducer } as any, {
      metaReducers: metaReducers,
      runtimeChecks: {
        strictActionImmutability: true,
        strictStateImmutability: true,
      },
    }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    EffectsModule.forRoot([]),
    HttpClientModule,
    CookieModule.forRoot({
      sameSite: 'strict',
      secure: true,
      expires: new TremazeDate().add(10, 'year'),
      storeUnencoded: !environment.production,
    }),
    BrowserTransferStateModule,
    SharedCoreAuthV2Module.forRoot(RemoteClientAuthV2DataSource, {
      useCookieStorage: true,
    }),
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    RouterModule.forRoot(
      [
        {
          path: '',
          loadChildren: () =>
            import('@tremaze/tremaze-client-website/main').then(
              (m) => m.TremazeClientWebsiteMainModule
            ),
        },
      ],
      {
        initialNavigation: 'enabledBlocking',
        scrollOffset: [0, 130],
        onSameUrlNavigation: 'reload',
      }
    ),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: BaseInterceptor, multi: true },
    { provide: AppConfigService, useClass: TremazeWebsiteConfigService },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' },
    },
    JsonSerializer,
    AppStateService,
    { provide: ProfileDataSource, useClass: ClientWebsiteProfileDataSource },
    { provide: DateAdapter, useClass: TremazeDateAdapter },
    { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
    { provide: LOCALE_ID, useValue: 'de-DE' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  static firstNav = false;

  constructor(
    router: Router,
    viewportScroller: ViewportScroller,
    @Inject(PLATFORM_ID) platformId,
    private readonly transferState: TransferState,
    private readonly store: Store<any>,
    ngZone: NgZone
  ) {
    /**
     * CONFIGURE how long to wait (in seconds)
     * before the pending tasks are dumped to the console.
     */
    const WAIT_SECONDS = 2;

    console.log(
      `⏳ ... Wait ${WAIT_SECONDS} seconds to dump pending tasks ... ⏳`
    );

    // Run the debugging `setTimeout` code outside of
    // the Angular Zone, so it's not considered as
    // yet another pending Zone Task:
    ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        // Access the NgZone's internals - TaskTrackingZone:
        const TaskTrackingZone = (ngZone as any)._inner._parent._properties
          .TaskTrackingZone;

        // Print to the console all pending tasks
        // (micro tasks, macro tasks and event listeners):
        console.debug('👀 Pending tasks in NgZone: 👀');
        console.debug({
          microTasks: TaskTrackingZone.getTasksFor('microTask'),
          macroTasks: TaskTrackingZone.getTasksFor('macroTask'),
          // eventTasks: TaskTrackingZone.getTasksFor('eventTask'),
        });

        // Advice how to find the origin of Zone tasks:
        console.debug(
          `👀 For every pending Zone Task listed above investigate the stacktrace in the property 'creationLocation' 👆`
        );
      }, 1000 * WAIT_SECONDS);
    });

    const isBrowser = this.transferState.hasKey<any>(NGRX_STATE);
    if (isBrowser) {
      this.onBrowser();
    } else {
      this.onServer();
    }
    router.events
      .pipe(filter((e): e is Scroll => e instanceof Scroll))
      .subscribe((e) => {
        if (e.position) {
          // backward navigation
          setTimeout(() => {
            viewportScroller.scrollToPosition(e.position);
          }, 0);
        } else if (e.anchor) {
          // anchor navigation
          setTimeout(
            () => {
              viewportScroller.scrollToAnchor(e.anchor);
            },
            isPlatformBrowser(platformId) && !AppModule.firstNav ? 500 : 0
          );
        } else if (AppModule.firstNav) {
          // forward navigation
          setTimeout(() => {
            viewportScroller.scrollToPosition([0, 0]);
          }, 0);
        }
        AppModule.firstNav = true;
      });
  }

  onServer() {
    this.transferState.onSerialize(NGRX_STATE, () => {
      let state;
      this.store
        .subscribe((saveState: any) => {
          // console.log('Set for browser', JSON.stringify(saveState));
          state = saveState;
        })
        .unsubscribe();

      return state;
    });
  }

  onBrowser() {
    const state = this.transferState.get<any>(NGRX_STATE, null);
    this.transferState.remove(NGRX_STATE);
    this.store.dispatch({ type: 'SET_ROOT_STATE', payload: state });
    // console.log('Got state from server', JSON.stringify(state));
  }
}

registerLocaleData(localeDe);
