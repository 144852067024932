import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxIndexedDBModule } from 'ngx-indexed-db';

function f(n: string) {
  return { name: n, keypath: n, options: { unique: false } };
}

// TODO: Create database service to allow lazy database stuff

@NgModule({
  imports: [
    CommonModule,
    NgxIndexedDBModule.forRoot({
      name: 'DB',
      version: 2,
      objectStoresMeta: [
        {
          store: 'event-reg-guest',
          storeConfig: { keyPath: 'id', autoIncrement: true },
          storeSchema: [
            'firstName',
            'lastName',
            'gender',
            'birth',
            'phone',
            'mobile',
            'email',
            'additionalInfo',
            'address',
          ].map(f),
        },
        {
          store: 'guest-users',
          storeConfig: { keyPath: 'id', autoIncrement: true },
          storeSchema: [
            'firstname',
            'lastname',
            'gender',
            'birth',
            'phone',
            'mobile',
            'email',
            'other',
          ].map(f),
        },
        {
          store: 'third-party-contacts',
          storeConfig: { keyPath: 'id', autoIncrement: true },
          storeSchema: [
            'firstname',
            'lastname',
            'phone',
            'mobile',
            'pickupPerson',
            'emergencyContact',
            'relationship',
          ].map(f),
        },
      ],
    }),
  ],
})
export class SharedCoreLocalDatabaseModule {}
