import { Inject, Injectable } from '@angular/core';
import { AuthV2Service } from './auth-service';
import {
  AuthenticatedUser,
  AuthenticatedUserTenant,
  TokenInfo,
} from '@tremaze/shared/auth/types';
import { Tenant } from '@tremaze/shared/feature/tenant/types';
import { combineLatest, interval, merge, Observable, Subject } from 'rxjs';
import { makeStateKey } from '@angular/platform-browser';
import { StorageInjectionToken } from '@tremaze/shared/core/storage';
import {
  catchError,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  map,
  mapTo,
  mergeMap,
  shareReplay,
  skip,
  startWith,
  switchMapTo,
  take,
  tap,
} from 'rxjs/operators';
import { AuthV2DataSource } from '@tremaze/shared/core/auth-v2/data-access';
import {
  catchErrorMapTo,
  filterNotNullOrUndefined,
  filterTrue,
  mapNotNullOrUndefined,
  negateBool,
} from '@tremaze/shared/util/rxjs';
import { isTokenExpired } from './helpers';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { TenantDataSource } from '@tremaze/shared/feature/tenant/data-access';
import * as Sentry from '@sentry/angular-ivy';

const TOKEN_KEY = '_T_I_';
const TENANT_KEY = '_TENANT_';
const AUTH_STATE_KEY = makeStateKey<AuthTransferState>('AuthV2ServiceImpl');

interface AuthTransferState {
  authenticatedUser?: AuthenticatedUser;
  tokenInfo?: TokenInfo;
}

@Injectable({ providedIn: 'root' })
export class AuthOidcService implements AuthV2Service {
  // Events
  private _loginEvent$ = new Subject<{ username: string; password: string }>();
  private _logoutEvent$ = new Subject();
  private _refreshTokenEvent$ = new Subject();
  private _selectTenantEvent$ = new Subject<Tenant>();
  private _reloadAuthenticatedUserEvent$ = new Subject();
  private _isRefreshingToken = false;

  // State

  constructor(
    private readonly _dataSource: AuthV2DataSource,
    private readonly _tenantDataSource: TenantDataSource,
    private readonly _http: HttpClient,
    private readonly _router: Router,
    @Inject(StorageInjectionToken)
    private readonly _storage?: Storage,
  ) {}

  private _tokenInfo$: Observable<TokenInfo | null> = merge(
    this._refreshTokenEvent$.pipe(
      map(() => this._tokenInfoFromLocalStorage),
      filterNotNullOrUndefined<TokenInfo>(),
      filter(() => !this._isRefreshingToken),
      tap(() => {
        this._isRefreshingToken = true;
      }),
      mergeMap(({ refresh_token }) =>
        this._dataSource.getRefreshedToken(refresh_token).pipe(
          catchError((e) => {
            Sentry.captureException(e);
            this._isRefreshingToken = false;
            this.logout();
            return this._router.navigate(['']).then(() => null);
          })
        )
      )
    ),
    this._loginEvent$.pipe(
      mergeMap(({ username, password }) =>
        this._obtainTokenInfo(username, password).pipe(catchErrorMapTo(null))
      )
    ),
    this._logoutEvent$.pipe(
      mapTo(null),
      tap(() => {
        this._removeStoredTenantId();
        this._removeStoredTokenInfo();
      })
    )
  ).pipe(
    tap((r) => {
      this._isRefreshingToken = false;
      if (r) {
        this._storeTokenInfo(r);
      }
    }),
    startWith(this._tokenInfoFromLocalStorage),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  get tokenInfo$(): Observable<TokenInfo> {
    return this._tokenInfo$.pipe(filterNotNullOrUndefined());
  }

  private _notAuthenticated$ = this._tokenInfo$.pipe(map(isTokenExpired));

  get notAuthenticated$(): Observable<boolean> {
    return this._notAuthenticated$;
  }

  private _authenticated$ = this._notAuthenticated$.pipe(negateBool());

  get authenticated$(): Observable<boolean> {
    return this._authenticated$;
  }

  private _authenticatedUserTenants$: Observable<Tenant[]> =
    this._authenticated$.pipe(
      filterTrue(),
      mergeMap(() => this._obtainAuthenticatedUserTenants()),
      shareReplay({
        bufferSize: 1,
        refCount: true,
      })
    );

  private _selectedTenant$ = merge(
    this._selectTenantEvent$,
    this._authenticatedUserTenants$.pipe(
      map((r) => {
        if (!r) {
          return;
        }
        if (r.length === 1) {
          return r[0];
        }
        return r.find((t) => t.id === this._storedTenantId);
      })
    )
  ).pipe(
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  get authenticatedUserTenants$(): Observable<AuthenticatedUserTenant[]> {
    return this._authenticatedUserTenants$;
  }

  private _authenticatedUser$: Observable<AuthenticatedUser> = merge(
    this._selectedTenant$.pipe(
      filterNotNullOrUndefined(),
      distinctUntilKeyChanged('id')
    ),
    this._reloadAuthenticatedUserEvent$.pipe(
      switchMapTo(this._selectedTenant$)
    ),
    interval(1000 * 30)
  ).pipe(
    filterNotNullOrUndefined(),
    mergeMap(() => this._dataSource.getAuthenticatedUser()),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  get authenticatedUser$(): Observable<AuthenticatedUser> {
    return this._authenticatedUser$;
  }

  private _reloadActiveTenantEvent$ = new Subject<void>();

  readonly activeTenant$: Observable<AuthenticatedUserTenant> = combineLatest([
    this._selectedTenant$,
    this._reloadActiveTenantEvent$.pipe(startWith(null)),
  ]).pipe(
    map(([tenant]) => tenant),
    filterNotNullOrUndefined(),
    mergeMap((tenant) => {
      return this._tenantDataSource.getTenant(tenant.id);
    }),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  get authenticated(): boolean {
    return !isTokenExpired(this._tokenInfoFromLocalStorage);
  }

  get authenticatedStateChanged$(): Observable<boolean> {
    return this.authenticated$.pipe(distinctUntilChanged());
  }

  private _hasActiveTenant$ = this._selectedTenant$.pipe(
    mapNotNullOrUndefined(),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  get hasActiveTenant$(): Observable<boolean> {
    return this._hasActiveTenant$;
  }

  get hasAuthenticatedUser$(): Observable<boolean> {
    return this._authenticatedUser$.pipe(mapNotNullOrUndefined());
  }

  get hasNoAuthenticatedUser$(): Observable<boolean> {
    return this.hasAuthenticatedUser$.pipe(negateBool());
  }

  private get _storedTenantId(): string | null {
    return this._storage?.getItem(TENANT_KEY);
  }

  private get _tokenInfoFromLocalStorage(): TokenInfo | null {
    const stored = this._storage?.getItem(TOKEN_KEY);
    if (stored) {
      return TokenInfo.deserialize(JSON.parse(stored));
    }
    return null;
  }

  getRefreshedToken(): Observable<TokenInfo | null> {
    this.refreshToken();
    return this._tokenInfo$.pipe(skip(1), take(1));
  }

  public selectTenant(tenant: Tenant): void {
    this._selectTenantEvent$.next(tenant);
  }

  init(): void {
    this._selectedTenant$
      .pipe(filterNotNullOrUndefined<Tenant>(), distinctUntilKeyChanged('id'))
      .subscribe(({ id }) => this._storeTenantId(id));
  }

  loginWithUsernameAndPassword(
    username: string,
    password: string
  ): Observable<boolean> {
    this._loginEvent$.next({ username, password });
    return this.authenticated$.pipe(skip(1), take(1));
  }

  logout(): void {
    this._logoutEvent$.next(null);
  }

  refreshToken(): void {
    this._refreshTokenEvent$.next(null);
  }

  reloadAuthenticatedUser(): void {
    this._reloadAuthenticatedUserEvent$.next(null);
  }

  reloadActiveTenant() {
    this._reloadActiveTenantEvent$.next(null);
  }

  private _storeTokenInfo(tokenInfo: TokenInfo): void {
    this._storage?.setItem(TOKEN_KEY, JSON.stringify(tokenInfo));
  }

  private _removeStoredTokenInfo(): void {
    this._storage?.removeItem(TOKEN_KEY);
  }

  private _removeStoredTenantId(): void {
    this._storage?.removeItem(TENANT_KEY);
  }

  private _storeTenantId(id?: string) {
    this._storage?.setItem(TENANT_KEY, id);
  }

  private _obtainAuthenticatedUserTenants(): Observable<Tenant[]> {
    return this._http
      .get<unknown[]>('/cc/users/me/tenants', {
        params: { skipTenantId: 'true' },
      })
      .pipe(
        map((r) =>
          r.map(Tenant.deserialize).sort((a, b) => a.name.localeCompare(b.name))
        )
      );
  }

  private _obtainTokenInfo(
    username: string,
    password: string
  ): Observable<TokenInfo | null> {
    return this._dataSource.loginWithUsernamePassword(username, password);
  }
}
