import { Injectable } from '@angular/core';
import {
  ProfileDataSource,
  UserProfileData,
} from '@tremaze/shared/feature/profile/data-access';
import { HttpClient } from '@angular/common/http';
import { JsonSerializer } from '@tremaze/shared/util-json-serializer';
import { Observable, of } from 'rxjs';
import { AuthenticatedUser } from '@tremaze/shared/auth/types';
import { catchError, map, mapTo, switchMap } from 'rxjs/operators';
import { User } from '@tremaze/shared/feature/user/types';
import { TremazeHttpResponse } from '@tremaze/shared/util-http/types';
import { jsonHttpHeaders } from '@tremaze/shared/util-http';

@Injectable({ providedIn: 'root' })
export class ClientWebsiteProfileDataSource implements ProfileDataSource {
  constructor(private http: HttpClient, private js: JsonSerializer) {}

  getProfileData(): Observable<AuthenticatedUser> {
    return this.http
      .get<AuthenticatedUser>(`app/user/me`)
      .pipe(map((r) => AuthenticatedUser.deserialize(r)));
  }

  setProfileData(userProfileData: UserProfileData): Observable<User> | null {
    return this.getProfileData().pipe(
      switchMap((currentProfileData) => {
        let req = of(true);
        if (userProfileData.email !== currentProfileData.email) {
          req = this.setEmail(userProfileData.email, userProfileData.password);
        }
        const payload = { ...userProfileData };
        delete payload.email;
        delete payload.password;
        return req.pipe(
          switchMap((r) => {
            if (!r) {
              throw r;
            }
            return this.http
              .post<TremazeHttpResponse<User>>(
                `/app/user/editUPProfile`,
                this.js.objectToJson(payload),
                { headers: jsonHttpHeaders }
              )
              .pipe(
                map((r) => {
                  if (r?.message === 'success') {
                    return r.object;
                  }
                  throw r?.message;
                })
              );
          })
        );
      })
    );
  }

  deleteAccount(): Observable<boolean> {
    return this.http.delete(`/app/user/deleteOwnUser`).pipe(
      mapTo(true),
      catchError(() => of(false))
    );
  }

  setPassword(
    newPassword: string,
    currentPassword: string
  ): Observable<boolean> {
    return this.http
      .post('/app/user/changeUserData', null, {
        params: { password: currentPassword, newPassword },
      })
      .pipe(mapTo(true));
  }

  private setEmail(newEmail: string, password: string) {
    return this.http
      .post('/app/user/changeUserData', null, {
        params: { password, newEmail },
      })
      .pipe(mapTo(true));
  }
}
