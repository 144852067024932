import { HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

export abstract class AppConfigService {
  abstract readonly basePath: string | Observable<string>;

  abstract readonly instId?: string;

  abstract readonly version: string;

  abstract readonly jsonIgnoreFields: string[];

  abstract readonly state: APP_CONFIG_STAGES;

  abstract readonly clientId: string;

  abstract readonly clientSecret: string;

  abstract readonly tokenPath?: string;

  abstract readonly productName?: string;

  abstract readonly logoPaths?: { light: string; dark: string; icon?: string };

  abstract readonly ksvsBasePath?: string;

  abstract readonly disableCustomerSelection?: boolean;

  readonly disablePermissionChecks?: boolean;

  abstract readonly allowMaterialComponents?: boolean;

  abstract readonly disableFreshChat?: boolean;

  abstract readonly hideWhatsNew?: boolean;

  abstract updateBasePath?(val: string): void;

  get isProd() {
    return this.state === 'PROD';
  }

  /***
   * Can be used to iron out differences in standard-modules between the different APIs
   */
  abstract httpInterceptor?(req: HttpRequest<any>): HttpRequest<any>;
}

export type APP_CONFIG_STAGES = 'PROD' | 'DEV' | 'QS';
