import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  of,
  ReplaySubject,
  Subject,
} from 'rxjs';
import {
  AuthenticatedUser,
  AuthV2ModuleConfig,
  AuthV2ModuleConfigInjectionToken,
  TokenInfo,
} from '@tremaze/shared/core/auth-v2/types';
import { AuthV2DataSource } from '@tremaze/shared/core/auth-v2/data-access';
import {
  catchError,
  distinctUntilChanged,
  filter,
  first,
  map,
  share,
  skip,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import { StorageInjectionToken } from '@tremaze/shared/core/storage';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { isPlatformServer } from '@angular/common';
import { Router } from '@angular/router';
import { AuthenticatedUserTenant } from '@tremaze/shared/auth/types';
import { mapNotNullOrUndefined } from '@tremaze/shared/util/rxjs';
import { Tenant } from '@tremaze/shared/feature/tenant/types';
import { AuthV2Service, WHITELISTED_USER_TYPES } from './auth-service';
import { isTokenExpired } from './helpers';

const TOKEN_KEY = '_T_I_';
const TENANT_KEY = '_TENANT_';
const AUTH_STATE_KEY = makeStateKey<AuthTransferState>('AuthV2ServiceImpl');

interface AuthTransferState {
  authenticatedUser?: AuthenticatedUser;
  tokenInfo?: TokenInfo;
}

@Injectable({ providedIn: 'root' })
export class AuthV2ServiceImpl implements AuthV2Service {
  private isRefreshingToken = false;
  private refreshedToken$ = new Subject<TokenInfo>();
  private _initialized = false;
  private _initializedAuthUser = false;
  private refreshTimeout: any;
  private _selectedTenant = new ReplaySubject<AuthenticatedUserTenant | null>(
    1
  );

  constructor(
    private readonly dataSource: AuthV2DataSource,
    private readonly router: Router,
    @Inject(PLATFORM_ID) private readonly platformId,
    @Optional()
    @Inject(AuthV2ModuleConfigInjectionToken)
    private readonly config?: AuthV2ModuleConfig,
    @Optional() private readonly transferState?: TransferState,
    @Optional()
    @Inject(StorageInjectionToken)
    private readonly storage?: Storage,
    @Optional()
    @Inject(WHITELISTED_USER_TYPES)
    private readonly whitelistedUserTypes?: string[]
  ) {
    if (transferState?.hasKey(AUTH_STATE_KEY)) {
      const { authenticatedUser, tokenInfo } = transferState.get(
        AUTH_STATE_KEY,
        null
      );
      transferState.remove(AUTH_STATE_KEY);
      const authUser = AuthenticatedUser.deserialize(authenticatedUser);
      const tI = TokenInfo.deserialize(tokenInfo);
      if (tI instanceof TokenInfo) {
        this._tokenInfo$.next(tI);
        this._initialized = true;
      }
      if (authUser instanceof AuthenticatedUser) {
        this._authenticatedUser$.next(authUser);
        this._initializedAuthUser = true;
      }
    }
  }

  get authenticated$(): Observable<boolean> {
    return this.isTokenExpired$.pipe(map((r) => !r));
  }

  get authenticatedStateChanged$(): Observable<boolean> {
    return this.authenticated$.pipe(skip(1), distinctUntilChanged());
  }

  get authenticated(): boolean {
    return !this.isTokenExpired;
  }

  get notAuthenticated$(): Observable<boolean> {
    return this.authenticated$.pipe(map((r) => !r));
  }

  private _authenticatedUser$ = new BehaviorSubject<AuthenticatedUser>(null);

  get authenticatedUser$(): Observable<AuthenticatedUser> {
    return this._authenticatedUser$.pipe(
      filter(() => this._initializedAuthUser)
    );
  }

  private _authenticatedUserTenants$ = this._authenticatedUser$.pipe(
    map((r) => {
      if (r?.tenants) {
        return r.tenants;
      }
    })
  );

  get authenticatedUserTenants$(): Observable<AuthenticatedUserTenant[]> {
    return this._authenticatedUserTenants$.pipe(map((r) => r ?? []));
  }

  private _activeTenant$: Observable<null | AuthenticatedUserTenant> =
    combineLatest([
      this._selectedTenant.pipe(startWith(null)),
      this._authenticatedUserTenants$,
    ]).pipe(
      map(([selectedTenant, authUserTenants]) => {
        if (!authUserTenants?.length) {
          return;
        }
        if (authUserTenants?.length == 1) {
          return authUserTenants[0];
        }
        if (
          selectedTenant &&
          authUserTenants.some((t) => t.id === selectedTenant.id)
        ) {
          return selectedTenant;
        }
      }),
      share()
    );

  get activeTenant$(): Observable<AuthenticatedUserTenant | null> {
    return this._activeTenant$;
  }

  get hasAuthenticatedUser$(): Observable<boolean> {
    return this.authenticatedUser$.pipe(
      map((r) => {
        return r instanceof AuthenticatedUser;
      })
    );
  }

  get hasNoAuthenticatedUser$(): Observable<boolean> {
    return this.hasAuthenticatedUser$.pipe(map((r) => !r));
  }

  private _tokenInfo$ = new BehaviorSubject<TokenInfo>(null);

  get tokenInfo$(): Observable<TokenInfo> {
    if (this.isRefreshingToken) {
      return this.refreshedToken$;
    }
    return this._tokenInfo$;
  }

  get hasActiveTenant$(): Observable<boolean> {
    return this.activeTenant$.pipe(mapNotNullOrUndefined());
  }

  private get hasToken$(): Observable<boolean> {
    return this._tokenInfo$.pipe(
      first(),
      map((t) => t instanceof TokenInfo)
    );
  }

  private get isTokenExpired$(): Observable<boolean> {
    return this._tokenInfo$.pipe(
      filter(() => this._initialized),
      map((t) => isTokenExpired(t))
    );
  }

  private get isTokenExpired(): boolean {
    return isTokenExpired(this._tokenInfo$.value);
  }

  loginWithUsernameAndPassword(
    username: string,
    password: string
  ): Observable<boolean> {
    return this.dataSource.loginWithUsernamePassword(username, password).pipe(
      switchMap((tokenInfo) => {
        this.onNewToken(tokenInfo);
        return this._tokenInfo$
          .pipe(first((t) => t?.access_token === tokenInfo?.access_token))
          .pipe(
            switchMap(() => this._reloadAuthenticatedUser()),
            map((r) => r instanceof AuthenticatedUser)
          );
      })
    );
  }

  logout(): void {
    this.clearStoredToken();
    this._tokenInfo$.next(null);
    this._authenticatedUser$.next(null);
    if (this.refreshTimeout) {
      clearTimeout(this.refreshTimeout);
    }
    this.router.navigate(['']);
  }

  getRefreshedToken(): Observable<TokenInfo | null> {
    if (this.isRefreshingToken) {
      return this.refreshedToken$.pipe(first((t) => !!t));
    }
    this.isRefreshingToken = true;

    return this._tokenInfo$.pipe(
      first(),
      tap((t) => {
        if (!(t instanceof TokenInfo)) {
          this.isRefreshingToken = false;
          throw '';
        }
      }),
      switchMap((tokenInfo) =>
        this.dataSource.getRefreshedToken(tokenInfo.refresh_token)
      ),
      tap((refreshed) => this.onNewToken(refreshed)),
      catchError((e) => {
        console.warn(e);
        return of(null);
      })
    );
  }

  refreshToken(): void {
    this.getRefreshedToken().subscribe();
  }

  reloadAuthenticatedUser(): void {
    this._reloadAuthenticatedUser().subscribe();
  }

  public async init(): Promise<void> {
    const tenantFromStorage = this.storage?.getItem(TENANT_KEY);
    if (tenantFromStorage) {
      this._selectedTenant.next(
        Tenant.deserialize(JSON.parse(tenantFromStorage))
      );
    }
    const tokenFromStorage = this.storage?.getItem(TOKEN_KEY);
    if (tokenFromStorage && tokenFromStorage !== 'undefined') {
      let token = TokenInfo.deserialize(JSON.parse(tokenFromStorage));
      if (isTokenExpired(token)) {
        token = await this.dataSource
          .getRefreshedToken(token.refresh_token)
          .toPromise();
      }
      this._tokenInfo$
        .pipe(
          filter((t) => !!t),
          first()
        )
        .subscribe(() => {
          this.reloadAuthenticatedUser();
        });
      this._initialized = true;
      this.onSetToken(token);
      this._tokenInfo$.next(token);
    } else {
      this._initialized = true;
      this._initializedAuthUser = true;
    }
  }

  selectTenant(tenant: Tenant): void {
    this._selectedTenant.next(tenant);
    this.storage?.setItem(TENANT_KEY, JSON.stringify(tenant));
  }

  private _reloadAuthenticatedUser(): Observable<AuthenticatedUser> {
    return this.dataSource.getAuthenticatedUser().pipe(
      tap((r) => {
        if (
          this.whitelistedUserTypes &&
          !r.userTypes?.some((u) => this.whitelistedUserTypes.includes(u.name))
        ) {
          return this.logout();
        }
        if (isPlatformServer(this.platformId)) {
          const transferState = this.getTransferState();
          transferState.authenticatedUser = r;
          this.transferState.set(AUTH_STATE_KEY, transferState);
        }
        if (!this._initializedAuthUser) {
          this._initializedAuthUser = true;
        }
        this._authenticatedUser$.next(r);
      })
    );
  }

  private clearStoredToken(): void {
    this.storage?.removeItem(TOKEN_KEY);
  }

  private getTransferState(): AuthTransferState {
    if (this.transferState?.hasKey(AUTH_STATE_KEY)) {
      return this.transferState.get(AUTH_STATE_KEY, null);
    }
    return {};
  }

  private onSetToken(token: TokenInfo) {
    if (isPlatformServer(this.platformId)) {
      const transferState = this.getTransferState();
      transferState.tokenInfo = token;
      this.transferState.set(AUTH_STATE_KEY, transferState);
    } else {
      const timeout = (token.expires_in ?? 5 * 60) * 1000;
      this.refreshTimeout = setTimeout(() => this.refreshToken(), timeout);
    }
  }

  private onNewToken(token: TokenInfo) {
    this.onSetToken(token);
    this.refreshedToken$.next(token);
    this.isRefreshingToken = false;
    this._tokenInfo$.next(token);
    this.storage?.setItem(TOKEN_KEY, JSON.stringify(token));
  }

  reloadActiveTenant(): void {
    throw new Error('Method not implemented.');
  }
}
