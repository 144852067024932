import { Address, ContactInfo, Equatable, Meta } from '@tremaze/shared/models';
import { TremazeDate } from '@tremaze/shared/util-date';
import { Gender } from '@tremaze/shared/feature/gender/types';
import { FileStorage } from '@tremaze/shared/feature/file-storage/types';
import {
  Deserializable,
  staticImplements,
} from '@tremaze/shared/util-decorators';
import { Role } from '@tremaze/shared/permission/types';
import { AuthenticatedUser } from '@tremaze/shared/auth/types';
import { Institution } from '@tremaze/shared/feature/institution/types';
import { UserInstitution } from '@tremaze/shared/feature/user/feature/allocation/types';
import { Department } from '@tremaze/shared/feature/department/types';

export type UserTypeName = 'EMPLOYEE' | 'USER';

export interface UserType {
  id: string;
  name: UserTypeName;
  viewName: string;
}

@staticImplements<Deserializable<User>>()
export class User implements Equatable {
  constructor(
    readonly id: string = null,
    readonly meta?: Meta,
    public username?: string,
    public firstName?: string,
    public lastName?: string,
    public profileImage?: FileStorage,
    public gender?: Gender,
    public contact: ContactInfo = new ContactInfo(),
    public address: Address = new Address(),
    public enabled?: boolean,
    readonly isAccountNonExpired?: boolean,
    readonly isAccountNonLocked?: boolean,
    public isCredentialsNonExpired?: boolean,
    readonly lastLogin?: TremazeDate,
    // TODO: This should be retrieved from an endpoint to split up the modules more
    public userInstitutions?: UserInstitution[],
    public roles: Role[] = [],
    public birth?: TremazeDate,
    public photoPublicationAllowed?: boolean,
    public other?: string,
    public approvedDataProtection?: boolean,
    public password?: string,
    public userTypes?: UserType[],
    public isUnapproved = false,
    public departments: Department[] = [],
    public isFakeAccount = false
  ) {}

  get fullName() {
    return [...([this.firstName] || ['']), ...([this.lastName] || [])].join(
      ' '
    );
  }

  get initials(): string {
    const l = [];
    if (this.firstName) {
      l.push(this.firstName[0].toUpperCase());
    }
    if (this.lastName) {
      l.push(this.lastName[0].toUpperCase());
    }
    return l.join('');
  }

  get isEmployee(): boolean {
    return this.userTypes?.some(
      (u: any) => (u.userType?.name ?? u.name) === 'EMPLOYEE'
    );
  }

  get isClient(): boolean {
    return this.userTypes?.some(
      (u: any) => (u.userType?.name ?? u.name) === 'USER'
    );
  }

  get instIds(): string[] {
    return this.userInstitutions?.map((i) => i.institution.id) ?? [];
  }

  get departmentInstIds(): string[] {
    return this.departments?.map((i) => i.institution.id) ?? [];
  }

  get institutions(): Institution[] {
    return this.userInstitutions?.map((u) => u.institution) ?? [];
  }

  get institutionDepartmentMap(): {
    institution: Institution;
    departments: Department[];
  }[] {
    const map = {};
    this.userInstitutions?.forEach((u) => {
      map[u.institution.id] = {
        institution: u.institution,
        departments: [],
      };
    });
    this.departments.forEach((d) => {
      if (map[d.institution?.id]) {
        map[d.institution.id].departments.push(d);
      }
    });
    return Object.values(map) as any;
  }

  static deserialize(data: any): null | User {
    if (!data) {
      return null;
    }
    let userInsts: UserInstitution[];
    if (data.userInstitutions) {
      userInsts = data.userInstitutions?.map(UserInstitution.deserialize);
    } else if (data.institutions) {
      userInsts = data.institutions.map((inst) =>
        UserInstitution.deserialize({
          institution: inst,
        })
      );
    }
    userInsts = userInsts?.sort((a, b) => a.institution.name.localeCompare(b.institution.name));
    return new User(
      data.id,
      Meta.deserialize(data),
      data.username?.trim(),
      (
        data.firstName ||
        data.firstname ||
        (data?.apUser || data?.upUser)?.firstname
      )?.trim(),
      (
        data.lastName ||
        data.lastname ||
        (data?.apUser || data?.upUser)?.lastname
      )?.trim(),
      FileStorage.deserialize(data.profileImage ?? data.avatar),
      Gender.deserialize(data.gender),
      data.contact
        ? ContactInfo.deserialize(data.contact)
        : new ContactInfo(
            data.email,
            data.mobile ?? (data?.apUser || data?.upUser)?.mobile,
            data.phone ?? (data?.apUser || data?.upUser)?.phone
          ),
      Address.deserialize(
        data.address || data.apUser?.address || data?.upUser?.address
      ) || new Address(),
      data.isEnabled ?? data.enabled,
      data.isAccountNonExpired,
      data.isAccountNonLocked,
      data.isCredentialsNonExpired,
      TremazeDate.deserialize(data.lastLogin),
      userInsts,
      data.roles?.map(Role.deserialize) || [],
      TremazeDate.utc(data.birth || (data?.apUser || data?.upUser)?.birth),
      data.photoPublicationAllowed ?? data.upUser?.photoPublicationAllowed,
      data.other ?? data.upUser?.allergies,
      data.approvedDataProtection ?? data.upUser?.approvedDataProtection,
      data.password,
      (data.userTypes as any[])?.map((u) => u.userType ?? u) ?? [],
      data.isUnapproved,
      (data.departments?.map(Department.deserialize) as Department[])?.sort(
        (a, b) => a.name.localeCompare(b.name)
      ) ?? [],
      data.isFake ?? false
    );
  }

  static sortByLastName(a: User, b: User): number {
    return a.lastName.localeCompare(b.lastName);
  }

  static fromAuthenticatedUser(a: AuthenticatedUser): User {
    return new User(
      a.userId,
      a.meta,
      a.username,
      a.firstName,
      a.lastName,
      a.profileImage,
      a.gender,
      a.contact,
      a.address,
      a.enabled,
      false,
      false,
      false,
      null,
      a.userInstitutions as any,
      [],
      a.birth,
    );
  }

  equals(other: User): boolean {
    return this.id === other.id;
  }
}
