import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthV2Service } from './auth-service';

@Injectable({ providedIn: 'root' })
export class TenantSelectedGuard implements CanActivate, CanLoad {
  constructor(private router: Router, private authService: AuthV2Service) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.authService.hasActiveTenant$.pipe(
      take(1),
      map((r) => {
        if (r) {
          return true;
        }
        return this.router.parseUrl('/select-tenant');
      })
    );
  }

  canLoad(): Observable<boolean | UrlTree> {
    return this.canActivate();
  }
}
