import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterService } from './router.service';

@NgModule({
  imports: [CommonModule],
})
export class SharedRouterModule {
  static forRoot(): ModuleWithProviders<SharedRouterModule> {
    return {
      ngModule: SharedRouterModule,
      providers: [
        RouterService,
        {
          provide: APP_INITIALIZER,
          useFactory: (ds: RouterService) => () => ds.init(),
          deps: [RouterService],
          multi: true,
        },
      ],
    };
  }
}
