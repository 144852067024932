import { TokenInfo } from '@tremaze/shared/auth/types';
import { TremazeDate } from '@tremaze/shared/util-date';

export function isTokenExpired(tokenInfo: TokenInfo | null): boolean {
  if (tokenInfo instanceof TokenInfo) {
    const expiration: TremazeDate = new TremazeDate(tokenInfo.expiration);
    return expiration.isBefore(TremazeDate.getNow());
  }
  return true;
}
