import { Injectable } from '@angular/core';
import { NavigationEnd, Router, UrlTree } from '@angular/router';
import { Location } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class RouterService {
  private history: string[] = [];

  constructor(private router: Router, private location: Location) {}

  public get canGoBack() {
    return this.history?.length > 1;
  }

  private static extractQueryString(url: string) {
    const qStart = url.indexOf('?');
    if (qStart >= 0) {
      return url.substr(qStart);
    }
    return '';
  }

  init() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects);
      }
    });
  }

  getBackURL(url?: string, pop = 1, andPush?: string, preserveQuery = true) {
    url = url ?? this.router.url;
    const split = url.split('/');
    let slice = split.slice(0, split.length - pop).join('/');
    const query = RouterService.extractQueryString(url);
    if (query && slice.endsWith(query)) {
      slice = slice.substr(0, slice.length - query.length);
    }
    return slice + (andPush || '') + (preserveQuery ? query : '');
  }

  getBackURLTree(
    url = this.router.url,
    pop = 1,
    andPush?: string,
    preserveQuery = true
  ): UrlTree {
    return this.router.parseUrl(
      this.getBackURL(url, pop, andPush, preserveQuery)
    );
  }

  getPopUntilURLTree(
    match: string,
    url?: string,
    popBeyond = false,
    andPush?: string,
    preserveQuery = true
  ) {
    return this.router.parseUrl(
      this.getPopUntilURL(match, url, popBeyond, andPush, preserveQuery)
    );
  }

  getPopUntilURL(
    match: string,
    url?: string,
    popBeyond = false,
    andPush?: string,
    preserveQuery = true
  ) {
    url = url || this.router.url;
    match = match.replace('/', '');
    const split = url.split('/');
    let popUntilIndex = split.length - 1;
    for (popUntilIndex; popUntilIndex >= 0; popUntilIndex--) {
      if (split[popUntilIndex].startsWith(match)) {
        break;
      }
    }
    if (!popBeyond) {
      popUntilIndex++;
    }
    let slice = split.slice(0, popUntilIndex).join('/');
    const query = RouterService.extractQueryString(url);
    if (query && slice.endsWith(query)) {
      slice = slice.substr(0, slice.length - query.length);
    }
    return slice + (andPush || '') + (preserveQuery ? query : '');
  }

  popUntil(
    match: string,
    url?: string,
    popBeyond = false,
    andPush?: string,
    preserveQuery = true
  ): void {
    // const newURL = this.getPopUntilURL(match, url, popBeyond, andPush);
    // if (this.history.includes(newURL)) {
    //   let popped = 0;
    //   while (this.history.pop() !== newURL) {
    //     popped++;
    //   }
    //   window.history.go(-popped);
    // } else {
    // }
    this.router.navigateByUrl(
      this.getPopUntilURL(
        match,
        url ?? this.router.url,
        popBeyond,
        andPush,
        preserveQuery
      ),
      { replaceUrl: true }
    );
  }

  back(): void {
    if (this.canGoBack) {
      this.history.pop();
      this.location.back();
    } else {
      const backURL = this.getBackURL();
      this.router.navigateByUrl(backURL, { replaceUrl: true });
    }
  }
}
